import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/tmp/89a06d9/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import TypesetStyle from 'components/TypesetStyle';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2 {...{
      "id": "expressive-type-sets"
    }}>{`Expressive type sets`}</h2>
    <p>{`Expressive type is reserved for use in editorial and digital marketing experiences which may require more dynamic typographic hierarchies and more variety in the Heading scale. The Expressive styles are curated to create a series of clear user expectations while still allowing for a more dramatic, graphic use of type.`}</p>
    <p>{`The Expressive styles below introduce the new IBM Design Language tokens. The difference between the Productive and Expressive styles is mainly evident in the Headings. Aside from the token names, which are specifically calibrated for product vs. editorial designers (e.g. `}<inlineCode parentName="p">{`$label-01`}</inlineCode>{` vs. `}<inlineCode parentName="p">{`$caption-01`}</inlineCode>{`) — the supporting and body styles have the same values in both the Productive and Expressive sets.`}</p>
    <TypesetStyle breakpointControls={false} typesets="supportingStyle,body,fixedHeading" mdxType="TypesetStyle" />
    <h2 {...{
      "id": "fluid-type-sets"
    }}>{`Fluid type sets`}</h2>
    <p>{`Fluid type is applied to large headline and display type styles. We set up fixed type scales at the breakpoints and are fluid in between. This helps us to maintain the quality of typography. Display styles are reserved only for graphic/visual elements. These should be used sparingly and thoughtfully within a user journey. It is not suitable for complex messaging, does not need to appear on every page.`}</p>
    <TypesetStyle breakpointControls={true} typesets="fluidHeadings,FluidParagraphsAndQuotes,fluidDisplay" mdxType="TypesetStyle" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      